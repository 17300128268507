@import "Styles/_variables.less";

.spacing {
    margin-top: 20px;
}
.cardsDiv {
    width: 100%;
    padding: 0px 5px 5px;
    margin-top: 12px;
    margin-bottom: 12px;
    justify-content: space-between;
}
