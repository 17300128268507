@import "Styles/_variables.less";

.root {
    .affix {
        width: 100%;
        background: @white !important;

        :global(.ant-affix) {
            background-color: @white !important;
            width: 100%;
            z-index: 15;
        }
    }

    .affixContent {
        padding: 24px 48px 24px;
        box-shadow: 0px 6px 6px -6px rgb(0 0 0 / 10%);
        background-color: @white !important;
    }
    .flex {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
