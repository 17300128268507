@import "Styles/_variables.less";
&.spacing {
    margin-bottom: @drawer-field-spacing;
}
&.steps {
    margin: @general-field-spacing 0px;
}

.cancelBtn {
    background-color: @lighter-gray;
}
