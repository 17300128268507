@import "Styles/_variables.less";

&.content {
    :global(.slick-slide) {
        padding: 0 10px;
    }
    :global(.slick-list) {
        padding: 0 -10px;
    }
}
