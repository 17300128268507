@import "Styles/_variables.less";
// .root{
&.leftContainer {
    background-color: @accent-color-darken;
    height: 100%;
}
&.container {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

&.welcome {
    font-size: 12px !important;
    font-weight: 700 !important;
}
&.descDiv {
    display: flex;
    justify-content: center;
    margin: 14px 0px;
}
&.imagesDiv {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
}
//}
