@import "Styles/_variables.less";

&.flexClass {
    justify-content: space-between;
    width: 100%;
}
&.title {
    display: flex;
    align-items: center;
}
&.filter {
    margin-top: 25px;
    // display: flex;
    // justify-content: space-between;
}
&.cardContainer {
    margin-top: 40px;
    margin-bottom: 12px;
}
