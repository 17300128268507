@import "~antd/dist/antd.variable.min.css";

body {
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}
:root {
    //colors
    --accent-color: #4b6085;
    --accent-color-darken: #385e94;
    --primary-color: #61bb88;
    --primary-color-lighten:#47a46f;
    --primary-color-darken:#408811;
    --highlight-color: #e9edf4;
    --background-color: #fafafa;
    --black: #000;
    --sky-blue: #e6f7ff;
    --royal-blue-two: #1890ff;
    --tertiary-color-light-3: #91d5ff;
    --white: #fff;
    --light-blue-hover: #eef2f8;
    --danger-color: #f5222d;
    --success-color: #52c41a;
    --disabled-bg: #f5f5f5;
    //fonts
    --primary-font: "Open Sans", sans-serif;
    --secondary-font: IBM Plex Sans;
    --secondary-font-1: "Montserrat", sans-serif;
    --secondary-font-2: "Rubik"; //need to test if its classes are in use
    --secondary-font-3: "Roboto", sans-serif;
}
.ant-pagination-item.ant-pagination-item-active {
    background-color: var(--accent-color) !important;
    &:hover {
        color: white;
        transition: transparent;
    }

    transition: transparent;

    > a {
        margin-top: 8px;
        width: 100%;
        height: 100%;
        font: normal normal normal 13px/15px Source Sans Pro;
        letter-spacing: 0px;

        &:hover {
            color: white;
        }
    }
}
