@import "Styles/_variables.less";

.root {
    &.title {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: @black;
    }
    &.link {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: @message-loading-icon-color;
        opacity: 0.5;
    }

    &.body {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: @tertiary-color;
    }

    &.table {
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: @accent-color-darken !important;
    }
    &.tableHeader {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: @black;
    }

    &.drawerSubtitles {
        color: @drawer-subtitles-color;
        font-size: 16px;
        font-weight: 600;
    }
    &.filter {
        color: @accent-color;
        font-size: 16px;
        font-weight: 600;
    }

    &.drawerContent {
        color: @table-header-color;
        font-size: 16px;
    }

    &.error {
        color: @danger-color;
        font-size: 0.8rem;
    }
    &.description {
        color: @inactive-tab-color;
        font-size: 14px;
    }

    &.score {
        color: @accent-color-darken;
        font-family:  var(--secondary-font-1);
        font-weight: 700;
        font-size: 20px;
    }
    &.askReportConfirmation {
        color: @black;
    }
}
