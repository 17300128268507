@import "Styles/_variables.less";
.root {
    &.exclamation {
        color: @info-icon-color;
        font-size: 12px;
    }
    &.question {
        color: @inactive-tab-color;
        font-size: 14px;
    }
}
