@import "Styles/_variables.less";

.root {
}
.spacing {
    margin-bottom: @drawer-field-spacing;
}

.flexClass {
    justify-content: space-between;
    width: 100%;
}
.filtersRow {
    margin-bottom: 40px;
}
