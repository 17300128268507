@import "Styles/_variables.less";

.root {
    &.header {
        box-shadow: @layout-header-box-shadow;
        z-index: 20;
        display: flex;
        align-items: center;
        background-color: @white !important;
        justify-content: space-between;
    }

    &.content {
        height: @layout-content-height;
        max-height: @layout-content-height;
        overflow-y: auto;
        padding: 0px 24px 24px;
    }

    &.layout {
        display: flex;
        flex: auto;
        flex-direction: column;
        min-height: 0;
        background: @white !important;
    }
}
