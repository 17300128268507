@import "Styles/_variables.less";
.root {
    &.card {
        height: 100%;
        border-color: @disabled-border;
        // margin-top: 12px;
        // margin-bottom: 12px;
        border-radius: 5px;
        box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
        :global(.ant-card-body) {
            padding: 15px;
        }
    }
    .tooltip {
        cursor: pointer;
        // &:not(:last-child) {
        //     margin-right: 8px;
        // }
        margin-left: 8px;
    }
    .body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .toolbar {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .contentContainer {
        margin-top: 24px;
        flex-grow: 1;
        min-height: 0px;
    }
    .cardTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        font-size: 24px;
    }
}
