@import "Styles/_variables.less";

.spacing {
}
.cardButton {
    width: @skeleton-card-button-width !important;
    min-width: @skeleton-card-button-width !important;
    border-radius: @skeleton-border-radius !important;
    height: @skeleton-card-input-height !important;
}
.circleButton {
    

}
.circleButton1 {
    width: 170px !important;
    height: 170px !important;
    margin-left: 38px;
    margin-top: 10px;
    
    

}

.connectionButton {
    width: @skeleton-card-button-width !important;
    min-width: @skeleton-card-button-width !important;
    
    height: @skeleton-card-input-height !important;
}
.cardInput1 {
    width: @skeleton-card-input1-width !important;
    height: @skeleton-card-input-height !important;
    border-radius: @skeleton-border-radius !important;
    // width: inherit !important;
}
.cardInput2 {
    // width: @skeleton-card-input2-width !important;
    height: @skeleton-card-input-height !important;
    // min-width: @skeleton-card-input2-width !important;
    border-radius: @skeleton-border-radius !important;
    width: 100% !important;
}
.cardInput3 {
    // width: @skeleton-card-input3-width !important;
    height: @skeleton-card-input-height !important;
    border-radius: @skeleton-border-radius !important;
    width: 100% !important;
    :global(.ant-skeleton-element .ant-skeleton-input) {
       width: 20px;
    }
}
.cardInput4 {
    // width: @skeleton-card-input4-width !important;
    height: @skeleton-card-input-height !important;
    border-radius: @skeleton-border-radius !important;
    width: 100% !important;
}
.cardInput4 {
    width: 200px !important;
    height: @skeleton-card-input-height !important;
    border-radius: @skeleton-border-radius !important;
    width: 100% !important;
}
.pagination {
    width: 492px !important;
    height: @skeleton-client-navigation-height !important;
    border-radius: @skeleton-border-radius !important;
}
.navigationInput1 {
    // width: @skeleton-client-navigation-input1-width !important;
    width: 100% !important;

    height: @skeleton-client-navigation-height !important;
    border-radius: @skeleton-border-radius !important;
}
.largeInput {
    // width: @skeleton-client-navigation-input1-width !important;
    width: 100% !important;

   
   
}
.createButton {
    // width: @skeleton-client-navigation-input1-width !important;
    width: 100% !important;

    height: 35px !important;
    border-radius: @skeleton-border-radius !important;
}
.navigationInput2 {
    width: 100% !important;
    // width: @skeleton-client-navigation-input2-width !important;
    height: @skeleton-client-navigation-height !important;
    border-radius: @skeleton-border-radius !important;
}
.navigationInput3 {
    width: 100% !important;
    // width: @skeleton-client-navigation-input3-width !important;
    height: @skeleton-client-navigation-height !important;
    border-radius: @skeleton-border-radius !important;
}
.navigationButton {
    width: @skeleton-client-navigation-button-width !important;
    min-width: @skeleton-client-navigation-button-width !important;
    height: @skeleton-client-navigation-button-height !important;
    border-radius: @skeleton-border-radius !important;
}
.reportNavigationButton {
    width: @skeleton-client-navigation-button-width !important;
    height:@skeleton-navigation-button-height !important;
    border-radius: @skeleton-border-radius !important;
}
.settingInput1 {
    width: 100% !important;
    height: @skeleton-setting-input-height !important;
    border-radius: @skeleton-border-radius !important;
}
.scoreCardInput1 {

    height: @skeleton-scoreCard-input1-height !important;
}
.reportTableInput {
    width: 100% ;
    height: @skeleton-report-table-input-height !important;
    border-radius: @skeleton-border-radius !important;

}
.scoreCardInput2 {
    width: 100% !important;
    // width: @skeleton-setting-input1-width!important;
    height: @skeleton-scoreCard-input2-height !important;
}
.card {
    width: 100% !important;
    // width: @skeleton-setting-input1-width!important;
    height: @skeleton-card-height !important;
    border-radius: @skeleton-border-radius !important;
}
.settingInput2 {
    height: @skeleton-setting-input-height !important;
    border-radius: @skeleton-border-radius !important;
}
.drawerInput1 {
    width: 100% !important;
    // width: @skeleton-drawer-input1-width!important;
    height: @skeleton-drawer-input1-height !important;
    border-radius: @skeleton-border-radius !important;
    width: 100% !important;
}
.drawerInput2 {
    height: @skeleton-drawer-input2-height !important;
    border-radius: @skeleton-border-radius !important;
    width: 100% !important;
}
.drawerTitle {
    // width: 100% !important;
    width: @skeleton-drawer-title-width!important;
    height: @skeleton-drawer-title-height !important;
    border-radius: @skeleton-border-radius !important;
}
.table1 {
    height: @skeleton-table1-input-height !important;
    width: 100% !important;
}
.table2 {
    height: @skeleton-table2-input-height !important;
    width: 100% !important;
}