@import "Styles/_variables.less";

.chartContainer {
    display: inline-block;
    position: relative;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        /* z-index: 999; */
        transform: translateX(-100%);
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 10%,
            rgba(255, 255, 255, 0) 20%,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 0.8) 60%,
            rgba(255, 255, 255, 0) 70%,
            rgba(255, 255, 255, 0) 80%,
            rgba(255, 255, 255, 0) 90%,
            rgba(255, 255, 255, 0) 100%
        );
        animation: shimmer 2.5s infinite;
        animation-play-state: running;
        content: "";
    }
    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}
