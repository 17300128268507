@import "Styles/_variables.less";
.root {
}
&.card {
    margin-top: 12px;
    margin-bottom: 12px;
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
}
&.cardTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
