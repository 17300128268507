@import "Styles/_variables.less";

.root {
    &.heading {
        font-family: var(--secondary-font-1);
    }

    &.heading1 {
        font-weight: 700;
        color: @accent-color;
        line-height: 24px;
        font-size: 28px;
        margin-bottom: 0;
    }

    &.heading2 {
        font-weight: 600;
        color: @accent-color;
        font-size: 20px;
        line-height: 24px;
    }
    &.heading3 {
        font-weight: 600;
        font-size: 20px;
        color: @accent-color;
        line-height: 24px;
    }
    &.tertiaryHeading {
        color: @tertiary-color;
    }
}
