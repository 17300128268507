@import "Styles/_variables.less";
.root {
    &.card {
        border-radius: 5px;
        margin:6px 0px;
        box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
        :global(.ant-card-body) {
            padding: 27px 15px;
        }
    }
}
