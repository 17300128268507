@import "Styles/_variables.less";

.spacing {
    margin-bottom: @drawer-field-spacing;
}
.row {
    height: 72vh;
    width: 100%;
}
.leftContainer {
    border-radius: 5px 0px 0px 5px;
    border: 1px solid @disabled-border;

    height: inherit;
}
.rightContainer {
    border-radius: 0px 5px 5px 0px;
    border: 1px solid @disabled-border;
    background-color: @background-color;

    height: inherit;
    padding: 30px 20px 20px;
}
.leftTitle {
    border-radius: 5px 0px 0px 0px;
    background-color: @background-color;
    color: @tertiary-color !important;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.createIntegration {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 21px 28px 12px;
}
.alignCenter {
    justify-content: center;
    margin: 16px;
}
.titleDiv {
    width: 100%;
    margin-top: @report-spacing;
    margin-bottom: @report-spacing;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.back {
    vertical-align: text-top;
}

.rowBorderBottomLightGray {
    border-bottom-color: 2px solid @light-gray-eight !important;
}
